import { builderAuthHeaderWithParams } from '../../common/utils/auth';
import {
  HOME_GET_ALL_PROJECTS_BEGIN,
  HOME_GET_ALL_PROJECTS_SUCCESS,
  HOME_GET_ALL_PROJECTS_FAILURE,
  HOME_GET_ALL_PROJECTS_DISMISS_ERROR,
} from './constants';
import axios from 'axios';

export function getAllProjects(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_GET_ALL_PROJECTS_BEGIN,
    });
    const { page, size } = args;
    const params = {
      page,
      size,
    };

    const options = builderAuthHeaderWithParams(params);
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/all`,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_GET_ALL_PROJECTS_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          dispatch({
            type: HOME_GET_ALL_PROJECTS_FAILURE,
            data: { error: err },
          });
          reject(err);
          console.log('error>>>>>getAllProjects', err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAllProjectsError() {
  return {
    type: HOME_GET_ALL_PROJECTS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_ALL_PROJECTS_BEGIN:
      return {
        ...state,
        getAllProjectsPending: true,
        getAllProjectsError: null,
      };

    case HOME_GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        getAllProjectsPending: false,
        getAllProjectsError: null,
        projectList: action.data,
      };

    case HOME_GET_ALL_PROJECTS_FAILURE:
      return {
        ...state,
        getAllProjectsPending: false,
        getAllProjectsError: action.data.error,
      };

    case HOME_GET_ALL_PROJECTS_DISMISS_ERROR:
      return {
        ...state,
        getAllProjectsError: null,
      };

    default:
      return state;
  }
}
