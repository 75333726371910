import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import CustomLoader from '../common/CustomLoader';
import CountryOption from '../common/utils/country-data.json';
import { profileValidationSchema } from '../common/utils/form-validation';

const ClientProfileForm = ({
  initialValues,
  onSubmit,
  updateProfilePending,
  handleCountryChange,
  onDeleteProfile,
  selectedCountry,
  onChange,
}) => {
  const countryWithLabel = CountryOption.find((e) => e.value === initialValues.country);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={profileValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, isSubmitting, setFieldValue }) => (
        <Form encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="businessName" className="form-label">
              Business Name
            </label>
            <Field
              type="text"
              name="businessName"
              placeholder="Business Name"
              value={values.businessName}
              onChange={(e) => onChange(e, setFieldValue, 'businessName')}
              className={`form-control ${
                touched.businessName && errors.businessName ? 'is-invalid' : ''
              }`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.businessName && errors.businessName && (
              <div className="invalid-feedback">{errors.businessName}</div>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <Field
                type="text"
                name="firstName"
                placeholder="First Name"
                value={values.firstName}
                onChange={(e) => onChange(e, setFieldValue, 'firstName')}
                className={`form-control ${
                  touched.firstName && errors.firstName ? 'is-invalid' : ''
                }`}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '0.25rem',
                  padding: '0.375rem 0.75rem',
                  width: '100%',
                }}
              />
              {touched.firstName && errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <Field
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={values.lastName}
                onChange={(e) => onChange(e, setFieldValue, 'lastName')}
                className={`form-control ${
                  touched.lastName && errors.lastName ? 'is-invalid' : ''
                }`}
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '0.25rem',
                  padding: '0.375rem 0.75rem',
                  width: '100%',
                }}
              />
              {touched.lastName && errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="emailAddress" className="form-label">
              Email Address
            </label>
            <Field
              type="email"
              name="emailAddress"
              readOnly
              placeholder="Email Address"
              value={values.emailAddress}
              className={`form-control ${
                touched.emailAddress && errors.emailAddress ? 'is-invalid' : ''
              }`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.emailAddress && errors.emailAddress && (
              <div className="invalid-feedback">{errors.emailAddress}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <Field
              name="country"
              render={({ field, form }) => (
                <Select
                  {...field}
                  options={CountryOption}
                  placeholder="Country"
                  value={selectedCountry || countryWithLabel}
                  onChange={(option) => {
                    handleCountryChange(option, form);
                    form.setFieldValue('country', option.value);
                  }}
                  onBlur={() => form.setFieldTouched('country', true)}
                />
              )}
            />
            <ErrorMessage name="country" component="div" className="invalid-feedback" />
          </div>
          {/* dont remove this cmt */}
          {/* <div className="mb-3">
            <label htmlFor="contact" className="form-label">
              Contact
            </label>
            <Field
              type="text"
              name="contact"
              placeholder="Contact"
              value={values.contact}
              onChange={(e) => onChange(e, setFieldValue, 'contact')}
              className={`form-control ${touched.contact && errors.contact ? 'is-invalid' : ''}`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.contact && errors.contact && (
              <div className="invalid-feedback">{errors.contact}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="oraganizationName" className="form-label">
              Organization Name
            </label>
            <Field
              type="text"
              name="oraganizationName"
              placeholder="Organization Name"
              value={values.oraganizationName}
              onChange={(e) => onChange(e, setFieldValue, 'oraganizationName')}
              className={`form-control ${
                touched.oraganizationName && errors.oraganizationName ? 'is-invalid' : ''
              }`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.oraganizationName && errors.oraganizationName && (
              <div className="invalid-feedback">{errors.oraganizationName}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="registerAddress" className="form-label">
              Register Address
            </label>
            <Field
              type="text"
              name="registerAddress"
              placeholder="Register Address"
              value={values.registerAddress}
              onChange={(e) => onChange(e, setFieldValue, 'registerAddress')}
              className={`form-control ${
                touched.registerAddress && errors.registerAddress ? 'is-invalid' : ''
              }`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.registerAddress && errors.registerAddress && (
              <div className="invalid-feedback">{errors.registerAddress}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="gst" className="form-label">
              GST
            </label>
            <Field
              type="text"
              name="gst"
              placeholder="GST"
              value={values.gst}
              onChange={(e) => onChange(e, setFieldValue, 'gst')}
              className={`form-control ${touched.gst && errors.gst ? 'is-invalid' : ''}`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.gst && errors.gst && <div className="invalid-feedback">{errors.gst}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="panNo" className="form-label">
              PAN
            </label>
            <Field
              type="text"
              name="panNo"
              placeholder="PAN"
              value={values.panNo}
              onChange={(e) => onChange(e, setFieldValue, 'panNo')}
              className={`form-control ${touched.panNo && errors.panNo ? 'is-invalid' : ''}`}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                padding: '0.375rem 0.75rem',
                width: '100%',
              }}
            />
            {touched.panNo && errors.panNo && (
              <div className="invalid-feedback">{errors.panNo}</div>
            )}
          </div> */}
          <hr className="form-divider" />
          <div className="text-center">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-outline-danger btn-md mt-4 mb-2 ml-5"
                onClick={onDeleteProfile}
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className="material-icons text-lg position-relative">delete</i>
                Delete
              </button>
              <button
                type="submit"
                disabled={isSubmitting || updateProfilePending}
                className="btn btn-success btn-md text-white mt-4 mb-2"
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className="material-icons text-lg position-relative">save</i>
                Update
                <CustomLoader loading={isSubmitting || updateProfilePending} />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientProfileForm;
