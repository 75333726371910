import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link } from 'react-router-dom';
import drapcodePng from '../../assets/img/DrapCode.png';
import Layout from './Layout';

export class CreateProject extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    document.title = 'Create Project-WebConnect';
  }

  render() {
    return (
      <Layout currentPage={'Create Project'}>
        <div className="container-fluid  d-flex flex-column mt-5">
          <div className="tm-section-title custom-h1-tm-section-title">
            {' '}
            <h3>Create Project</h3>
          </div>

          <div className="row justify-content-center mt-7">
            <div className="col-md-12">
              <section className="top-bottom-140-padding">
                <div className="container-fluid">
                  <div className="row justify-content-center mb-3">
                    <div className="col-md-5 mb-3">
                      <div className="card" style={{}}>
                        <div className="card-body  flex-column  align-items-center">
                          <h5 className="card-title">
                            Create a blank Project{' '}
                            <span style={{ visibility: 'hidden' }}>or Empty Project</span>
                          </h5>
                          <hr className="dark horizontal" />
                          <div className="d-flex justify-content-start">
                            <Link
                              to="/client/create-project"
                              className="btn btn-success btn-md text-white"
                            >
                              <i className="material-icons text-lg position-relative">
                                arrow_forward
                              </i>{' '}
                              Continue
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card">
                        <div className="card-body  flex-column  align-items-center">
                          <h5 className="card-title">Clone from a Template or Existing Project</h5>
                          <hr className="dark horizontal " />
                          <div className="d-flex justify-content-start">
                            <Link
                              to="/client/template-select"
                              className="btn btn-success btn-md text-white"
                            >
                              <i className="material-icons text-lg position-relative">
                                arrow_forward
                              </i>{' '}
                              Continue
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
