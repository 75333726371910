import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
export class DeveloperApis extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    previewURL: process.env.REACT_APP_EXCHANGE_PREVIEW_ENGINE,
    buildURL: process.env.REACT_APP_EXCHANGE_BUILD_ENGINE,
  };

  render() {
    const { project } = this.props;
    const { protocol, buildURL } = this.state;
    return (
      <>
        <div className="card-header">
          <h4>Developer's API</h4>
        </div>
        <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
        <div className="d-flex justify-content-center m-4">
          <a
            href={`${protocol}://${project.seoName}${buildURL}/docs/`}
            target="_blank"
            className="btn btn-success"
          >
            <i className="material-icons text-lg position-relative">description</i> Documentation
          </a>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperApis);
