import initialState from './initialState';
import { reducer as loginReducer } from './login';
import { reducer as signUpReducer } from './signUp';
import { reducer as forgotPasswordReducer } from './forgotPassword';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as confirmClientRegisterReducer } from './confirmClientRegister';

const reducers = [loginReducer, signUpReducer, forgotPasswordReducer, resetPasswordReducer, confirmClientRegisterReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
