import cookie from 'react-cookies';
import * as Yup from 'yup';

export const projectPermissions = [
  { label: 'Project not allowed to clone', value: 'CLONE_NOT_ALLOWED' },
  {
    label: 'Project allowed to clone after approval',
    value: 'CLONE_WITH_PERMISSION',
  },
  {
    label: 'Project can be clone without permission',
    value: 'CLONE_WITHOUT_PERMISSION',
  },
];

export const bsFrameworkVersionOptions = [
  { value: 'BOOTSTRAP_4_6', version: '4.6', label: 'Bootstrap 4' },
  { value: 'BOOTSTRAP_5_2', version: '5.2', label: 'Bootstrap 5' },
];
export const planOptions = [
  { value: 'FREE', label: 'Forever Free' },
  {
    value: 'PAID_1',
    label: 'Starter Plan',
  },
  {
    value: 'PAID_2',
    label: 'Professional Plan',
  },
  {
    value: 'PAID_3',
    label: 'Growth Plan',
  },
  {
    value: 'PAID_4',
    label: 'Enterprise 1',
  },
  {
    value: 'PAID_5',
    label: 'Enterprise 2',
  },
  {
    value: 'PAID_6',
    label: 'Enterprise 3',
  },
  {
    value: 'PAID_7',
    label: 'Enterprise 4',
  },
]