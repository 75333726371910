import {
  Register,
  Dashboard,
  CreateProject,
  ClientSidebar,
  CreateProjectForm,
  OpenBuilder,
  ProjectDetail,
  Setting,
  ChangeProjectPermission,
  TransferOwnership,
  TeamMember,
  InviteTeamMember,
  ClientProfile,
  ClientAccounts,
  ClientPricingCards,
  TemplateSelect,
  CloneProject,
  OpenBuilderCwp,
  OpenBuilderCp,
  ClientDetail,
  Layout,
  DeveloperApis,
} from './';

export default {
  path: '',
  childRoutes: [
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, isPrivate: true },
    {
      path: '/client/create-project-select',
      name: 'Create project',
      component: CreateProject,
      isPrivate: true,
    },
    {
      path: '/client/create-project',
      name: 'Create project form',
      component: CreateProjectForm,
      isPrivate: true,
    },
    {
      path: '/client/:projectUuid/open-builder',
      name: 'Open builder',
      component: OpenBuilder,
      isPrivate: true,
    },
    {
      path: '/client/:projectUuid/project-detail',
      name: 'Project detail',
      component: ProjectDetail,
      isPrivate: true,
    },
    {
      path: '/client/:projectUuid/settings',
      name: 'Setting',
      component: Setting,
      isPrivate: true,
    },
    {
      path: '/client/change-project-permission',
      name: 'Change project permission',
      component: ChangeProjectPermission,
      isPrivate: true,
    },
    {
      path: '/client/transfer-ownership',
      name: 'Transfer ownership',
      component: TransferOwnership,
      isPrivate: true,
    },
    {
      path: '/client/team-member',
      name: 'Team member',
      component: TeamMember,
      isPrivate: true,
    },
    {
      path: '/client/invite-team-member',
      name: 'Invite team member',
      component: InviteTeamMember,
      isPrivate: true,
    },
    {
      path: '/client/profile',
      name: 'Client profile',
      component: ClientProfile,
      isPrivate: true,
    },
    {
      path: '/client/accounts',
      name: 'Client accounts',
      component: ClientAccounts,
      isPrivate: true,
    },
    {
      path: '/client/pricing-cards',
      name: 'Client pricing cards',
      component: ClientPricingCards,
    },
    {
      path: '/client/template-select',
      name: 'Template select',
      component: TemplateSelect,
      isPrivate: true,
    },
    {
      path: '/client/:projectUuid/clone',
      name: 'Clone project',
      component: CloneProject,
      isPrivate: true,
    },
    {
      path: '/client/:projectUuid/open-builder-clone-without-permission',
      name: 'Open builder cwp',
      component: OpenBuilderCwp,
      isPrivate: true,
    },
    {
      path: '/client/open-builder-clone-with-permission',
      name: 'Open builder cp',
      component: OpenBuilderCp,
      isPrivate: true,
    },
    {
      path: '/client/:clientId/detail',
      name: 'Client detail',
      component: ClientDetail,
      isPrivate: true,
    },
    { path: '/client/layout', name: 'Layout', component: Layout, isPrivate: true },
    { path: '/client/developer-apis', name: 'Developer apis', component: DeveloperApis },
  ],
};
