import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link } from 'react-router-dom';
import drapcodePng from '../../assets/img/DrapCode.png';
import CustomPagination from '../../common/CustomPagination';
import Layout from './Layout';
import voca from 'voca';

export class TemplateSelect extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentPage: 1,
    perPage: 6,
  };

  async componentDidMount() {
    document.title = 'Dashboard-WebConnect';
    const { actions } = this.props;
    const { currentPage, perPage } = this.state;
    actions.clientProjects({ page: currentPage - 1, size: perPage });
    actions.getTemplates({ page: currentPage - 1, size: perPage });
  }
  handlePageChange = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;

    const newPage = Math.max(
      1,
      Math.min(page, Math.ceil(this.props.home.clientProjects.totalPages)),
    );

    this.setState({ currentPage: newPage }, () => {
      actions.clientProjects({ page: newPage - 1, size: perPage });
    });
  };
  handlePageChangeTemp = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;

    const newPage = Math.max(
      1,
      Math.min(page, Math.ceil(this.props.home.templatesData.totalPages)),
    );

    this.setState({ currentPage: newPage }, () => {
      actions.getTemplates({ page: newPage - 1, size: perPage });
    });
  };
  renderCard(item) {
    return (
      <div className="col-md-3 mb-4" key={item.id}>
        <div className="card">
          <img src={drapcodePng} className="card-img-top" alt={`Image for ${item.name}`} />
          <div className="card-body d-flex flex-column">
            <div>
              <h5 className="card-title" title={item.name}>
                {' '}
                {voca.truncate(voca.titleCase(item.name), 18)}
              </h5>
              <hr class="dark horizontal my-3" />
            </div>

            <div className="mt-auto text-right d-flex justify-content-end">
              <Link to={`/client/${item.uuid}/clone`}>
                <button type="button" className="btn btn-success btn-sm text-white">
                  <i className="material-icons text-lg position-relative">content_copy</i> Clone
                  Project
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTemplates(template) {
    const { REACT_APP_S3_UPLOAD_URL } = this.props;
    return (
      <div className="col-md-3 mb-4" key={template.id}>
        <div className="card">
          <img
            src={REACT_APP_S3_UPLOAD_URL + template.images[0]}
            className="card-img-top"
            alt={`Image for ${template.title}`}
            width="100%"
            height="200px"
          />
          <div className="card-body d-flex flex-column">
            <div>
              <h5 className="card-title">
                {' '}
                <a href={template.livePreviewUrl} target="_blank">
                  {voca.truncate(voca.titleCase(template.title), 15)}
                </a>
              </h5>
              <hr class="dark horizontal my-3" />
            </div>

            <div className="mt-auto text-right d-flex justify-content-end">
              <a href={template.projectCloneUrl} target="_blank">
                <button type="button" className="btn btn-success btn-sm text-white">
                  <i className="material-icons text-lg position-relative">content_copy</i> Clone
                  Template
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoDataMessage(section) {
    return (
      <div className="text-center">
        <h3>No {section} available to clone!</h3>
      </div>
    );
  }

  render() {
    const { home } = this.props;
    const { clientProjects, templatesData } = home;
    const { content, totalPages } = clientProjects ? clientProjects : {};
    const { currentPage } = this.state;
    return (
      <Layout currentPage={'Select Project Templates'}>
        <div className=" mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="container">
                <div className="row">
                  {content && content.length > 0
                    ? content && content.map((project) => this.renderCard(project))
                    : this.renderNoDataMessage('projects')}
                </div>
              </div>
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={this.handlePageChange}
              />
              <hr class="dark horizontal my-5" />
              <h2 className="mb-4 mt-5">Templates</h2>
              <div className="container mt-6 mb-9">
                <div className="row">
                  {templatesData.content && templatesData.content.length > 0
                    ? templatesData.content &&
                      templatesData.content.map((template) => this.renderTemplates(template))
                    : this.renderNoDataMessage('templates')}
                </div>
              </div>
              <CustomPagination
                currentPage={currentPage}
                totalPages={templatesData.totalPages}
                handlePageChange={this.handlePageChangeTemp}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    REACT_APP_S3_UPLOAD_URL: process.env.REACT_APP_S3_UPLOAD_URL,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelect);
