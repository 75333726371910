import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Select from 'react-select';
import { Formik, Form, Field } from 'formik';
import { projectPermissions } from '../common/constants';
export class ChangeProjectPermission extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    PROJECT_APP_DOMAIN: process.env.REACT_APP_PROJECT_DOMAIN,
    isCopied: false,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
  };
  copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.setState({ isCopied: true });
      })
      .catch((error) => {
        console.error('Unable to copy to clipboard', error);
      });
  };
  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const { selectedPermission } = values;
      const { home, actions } = this.props;
      const { project } = home;
      const { uuid } = project ? project : {};

      const data = {
        status: selectedPermission.value,
      };

      await actions.changeProjectPermission({ projectId: uuid, data });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };
  render() {
    const { PROJECT_APP_DOMAIN, isCopied, protocol } = this.state;
    const { home } = this.props;
    const { project } = home;
    const { uuid } = project ? project : {};

    return (
      <>
        <div className="card-header">
          <h4>Cloning Settings</h4>
        </div>
        <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
        <Formik initialValues={{ selectedPermission: null }} onSubmit={this.handleSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="card-body">
                <div className="row">
                  <div className="">
                    <div className="d-flex align-items-center">
                      <Select
                        value={values.selectedPermission}
                        onChange={(value) => setFieldValue('selectedPermission', value)}
                        options={projectPermissions}
                        placeholder="Choose Project Permission"
                        className="flex-grow-1"
                      />
                      <button
                        type="submit"
                        className="btn btn-md"
                        style={{
                          backgroundColor: '#fa5c28',
                          color: 'white',
                          marginLeft: '5px',
                          marginTop: '13px',
                          padding: '9px',
                        }}
                        disabled={
                          !values.selectedPermission ||
                          values.selectedPermission.value === 'write' ||
                          isSubmitting
                        }
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                {isCopied && (
                  <span className="p-1">
                    <i className="fas fa-check"></i> Copied
                  </span>
                )}
                <button
                  onClick={() =>
                    this.copyToClipboard(`${protocol}://${PROJECT_APP_DOMAIN}/client/${uuid}/clone`)
                  }
                  type="button"
                  className="btn btn-success btn-md text-white"
                >
                  <i className="material-icons text-lg position-relative">file_copy</i> Copy Clone
                  URL
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeProjectPermission);
