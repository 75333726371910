import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import img1 from '../../assets/img/open-builder-1.png';
import img2 from '../../assets/img/open-builder-2.png';
import img3 from '../../assets/img/open-builder-3.png';
import Layout from './Layout';
export class OpenBuilderCwp extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  async componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions, match } = this.props;
    const { params } = match;
    const { projectUuid } = params;
    await actions.fetchProject({ projectId: projectUuid });
    await actions.clonePendingRequest();
    await actions.projectCloneStatus();
    await actions.clientPendingRequest();
  }
  calculateProjectStepPer = (project) => {
    if (!project) {
      return 0;
    }
    const { steps } = project;
    if (!steps || steps.length === 0) {
      return 0;
    }
    const perComplete = steps.map((step) => step.value).reduce((acc, step) => acc + step);
    return perComplete;
  };
  render() {
    const { home, login } = this.props;
    const { project } = home ? home : {};
    const { protocol, builderSurface } = this.props;
    const token = login.token;
    const perComplete = this.calculateProjectStepPer(project);
    return (
      <Layout currentPage={'OpenBuilder'}>
        <div className="row">
          <div className="col-lg-12">
            <main className="text-center p-5">
              <h2 className="mb-3">What to do next?</h2>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <img src={img1} alt="Build your Web App" className="img-fluid" />
                  <h4>Build your Web App</h4>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <img src={img2} alt="Add Collections & Workflows" className="img-fluid" />
                  <h4>Add Collections & Workflows</h4>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <img src={img3} alt="Publish & Go Live" className="img-fluid" />
                  <h4>Publish & Go Live</h4>
                </div>
              </div>
              <br />
              <a
                href={`${protocol}://${project.seoName}.${builderSurface}editor/1/v/${project.uuid}/loader?token=${token}`}
                target="_blank"
                className="d-lg-inline-block m-btn m-btn-lg m-btn-theme"
              >
                Start Designing your App
              </a>
            </main>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderSurface: process.env.REACT_APP_BUILDER_SURFACE,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenBuilderCwp);
