const initialState = {
  token: '',
  role: '',
  uuid: '',
  username: '',
  name: '',
  country: '',
  isLoggedIn: false,
  loginPending: false,
  loginError: null,
  signUpPending: false,
  signUpError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  confirmClientRegisterPending: false,
  confirmClientRegisterError: null,
};

export default initialState;
