import React, { Component } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
export default class CustomLoader extends Component {
  render() {
    const showLoader = this.props.loading;

    let loaderCSS = {
      display: 'inline-block',
    };

    let loaderSize = '6px';
    let loaderMargin = '2px';
    let loaderColor = '#ffff';

    if (this.props.css) {
      loaderCSS = this.props.css;
    }
    if (this.props.size) {
      loaderSize = this.props.size;
    }
    if (this.props.color) {
      loaderColor = this.props.color;
    }
    if (this.props.margin) {
      loaderMargin = this.props.margin;
    }

    return (
      <BeatLoader
        css={loaderCSS}
        size={loaderSize}
        margin={loaderMargin}
        color={loaderColor}
        loading={showLoader}
      />
    );
  }
}
