import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import DashboardFooter from './Footer';
import { Link } from 'react-router-dom';
export class Layout extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getAllProjects();
    actions.clientDetails();
  }

  toggleSidebarNav = () => {
    document.body.classList.toggle('g-sidenav-pinned');
  };

  render() {
    const { home, login, currentPage = '', children } = this.props;
    const { projectList, clientDetail } = home;
    const { name } = login;
    const { maxFreeProjects } = clientDetail ? clientDetail : {};
    const { totalProjects } = projectList ? projectList : {};
    return (
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg min-vh-100 d-flex flex-column">
        <nav
          className="navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl blur shadow-blur left-auto"
          id="navbarBlur"
          data-scroll="true"
        >
          <div className="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm">
                  <Link className="opacity-5 text-dark" to={'/dashboard'}>
                    <i className="material-icons opacity-10">home</i>
                  </Link>
                </li>
                <li className="breadcrumb-item text-sm text-dark active" aria-current="page">
                  {currentPage}
                </li>
              </ol>
              <h6 className="font-weight-bolder mb-0">{currentPage}</h6>
            </nav>
            <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
              <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                {/* <div className="input-group input-group-outline">
                  <label className="form-label">Type here...</label>
                  <input type="text" className="form-control" onfocus="focused(this)" onfocusout="defocused(this)" />
                </div> */}
              </div>
              <ul className="navbar-nav  justify-content-end">
                {/* {!totalProjects === 0 &&
                  !currentPage === 'Dashboard' &&
                  (totalProjects < maxFreeProjects ? (
                    <li className="nav-item d-flex align-items-center">
                      <Link
                        className="btn btn-success text-white btn-md mb-0 me-3"
                        to={'/client/create-project-select'}
                      >
                        <i className="fa fa-plus me-sm-1"></i>
                        Create Project
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item px-3 d-flex align-items-center">
                      Free project limit exceeded.
                    </li>
                  ))} */}

                {/* {totalProjects < maxFreeProjects ? (
                  <li className="nav-item d-flex align-items-center">
                    <Link
                      className="btn btn-success text-white btn-md mb-0 me-3"
                      to={'/client/create-project-select'}
                    >
                      {' '}
                      <i className="fa fa-plus me-sm-1"></i>
                      Create Project
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item px-3 d-flex align-items-center">
                    Free project limit exceeded.
                  </li>
                )} */}
                <li className="mt-2">
                  <span></span>
                </li>
                <li className="nav-item d-xl-none px-3 d-flex align-items-center">
                  <a
                    href="#"
                    className="nav-link p-0"
                    id="iconNavbarSidenav"
                    onClick={this.toggleSidebarNav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line bg-dark"></i>
                      <i className="sidenav-toggler-line bg-dark"></i>
                      <i className="sidenav-toggler-line bg-dark"></i>
                    </div>
                  </a>
                </li>
                <li className="nav-item d-flex align-items-center">
                  <Link to={'/client/profile'} className="nav-link font-weight-bold px-0">
                    <i className="fa fa-user me-sm-1" aria-hidden="true"></i>
                    <span className="d-sm-inline d-none">{name}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid px-2 px-md-4">{children}</div>
        <DashboardFooter />
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
