import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as actions from './redux/actions';

import ClientSidebar from './ClientSidebar';
const toastrStyle = {
  width: 'auto',
  minWidth: '15vw',
};
export class App extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    const { login, history, children } = this.props;
    const { token } = login;
    return (
      <div className="home-app">
        <div className="page-container">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={toastrStyle}
          />
          {token ? <ClientSidebar history={history} /> : ''}
          {children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
