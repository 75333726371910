import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { loadStripe } from '@stripe/stripe-js';

export class ClientPricingCards extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    clientId: '',
  };
  async componentDidMount() {
    const { actions } = this.props;
    await actions.clientDetails();
    const { home } = this.props;
    const { clientDetail } = home;
    const clientId = clientDetail ? clientDetail.uuid : null;
    this.setState({ clientId });
  }

  handleUpgrade = async (playType, priceId) => {
    try {
      const { actions } = this.props;
      const { clientId } = this.state;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
      const res = await actions.stripePayment({ priceId, playType, projectId: clientId });
      if (res && res.data && res.data.id) {
        stripe.redirectToCheckout({ sessionId: res.data.id });
      } else {
        console.error('Invalid API response:', res);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  render() {
    const { login } = this.props;
    const country = login.country;
    const getPriceAndCurrency = (usdPrice, inrPrice) => {
      const currencyStyle = { fontWeight: 'bold', fontSize: '28px' };
      const formattedPrice =
        country === 'IN' ? (
          <div>
            ₹<span style={currencyStyle}>{inrPrice}</span>
          </div>
        ) : (
          <div>
            $<span style={currencyStyle}>{usdPrice}</span>
          </div>
        );

      return formattedPrice;
    };
    const getCustom = () => {
      const currencyStyle = { fontWeight: 'bold', fontSize: '28px' };
      return (
        <div className="text-center">
          <h5 style={currencyStyle}>Custom</h5>
        </div>
      );
    };
    return (
      <div className="row mb-6">
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-3 text-center">Professional Plan</h4>
              <div className="text-center">
                <h5 style={{ display: 'inline-block' }}>{getPriceAndCurrency(95, 6800)}</h5>
                <h5 style={{ marginLeft: '5px', display: 'inline-block', color: '#344767' }}>
                  /monthly
                </h5>
                <p className="text-center">
                  {' '}
                  <span style={{ color: '#5A5A5A', visibility: 'hidden' }}>
                    (Starts with $650/month)
                  </span>
                </p>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn btn-success btn-lg text-white"
                    onClick={() => this.handleUpgrade('PAID_2', 'price_1KItyAAKxaJIoGd2NT6fY5KB')}
                  >
                    <i className="material-icons text-lg position-relative">upgrade</i> Upgrade
                  </button>
                </div>
              </div>
              <hr class="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span style={{ color: '#5A5A5A' }}>View Details</span>
                  <i
                    className="fas fa-info-circle"
                    style={{ textDecoration: 'none', color: '#344767', marginLeft: '5px' }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-4 text-center">Growth Plan</h4>
              <div className="text-center">
                <h5 style={{ display: 'inline-block' }}>{getPriceAndCurrency(275, 19500)}</h5>
                <h5 style={{ marginLeft: '5px', display: 'inline-block', color: '#344767' }}>
                  /monthly
                </h5>
                <p className="text-center">
                  {' '}
                  <span style={{ color: '#5A5A5A', visibility: 'hidden' }}>
                    (Starts with $650/month)
                  </span>
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-success btn-lg text-white"
                    onClick={() => this.handleUpgrade('PAID_3', 'price_1KItyiAKxaJIoGd2xRHA4zI0')}
                  >
                    <i className="material-icons text-lg position-relative">upgrade</i> Upgrade
                  </button>
                </div>
              </div>
              <hr class="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span style={{ color: '#5A5A5A' }}>View Details</span>
                  <i
                    className="fas fa-info-circle"
                    style={{ textDecoration: 'none', color: '#344767', marginLeft: '5px' }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 mb-xl-0 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-4 text-center">Enterprise Plan</h4>
              {getCustom()}
              <p className="text-center">
                {' '}
                <span style={{ color: '#5A5A5A' }}>(Starts with $650/month)</span>
              </p>
              <div className="d-flex justify-content-center">
                <a href="https://drapcode.com/contact-us" target="_blank">
                  <button type="button" className="btn btn-success btn-lg text-white">
                    <i className="material-icons">mail</i> Contact Us
                  </button>
                </a>
              </div>
              <hr class="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span style={{ color: '#5A5A5A' }}>View Details</span>
                  <i
                    class="fas fa-info-circle"
                    style={{ textDecoration: 'none', color: '#344767', marginLeft: '5px' }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPricingCards);
