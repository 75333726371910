import {
  HOME_GET_VERSIONS_BEGIN,
  HOME_GET_VERSIONS_SUCCESS,
  HOME_GET_VERSIONS_FAILURE,
  HOME_GET_VERSIONS_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
export function getVersions(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_GET_VERSIONS_BEGIN,
    });

    const { projectId } = args;

    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BUILDER_ENGINE}versions/project/latest/${projectId}/version`,
        );

        if (response.status === 200) {
          console.log('response.data', response.data);
          if (response.data) {
            dispatch({
              type: HOME_GET_VERSIONS_SUCCESS,
              data: response.data,
            });
            resolve(response.data);
          } else {
            dispatch({
              type: HOME_GET_VERSIONS_SUCCESS,
              data: { version: 1 },
            });
            resolve({ version: 1 });
          }
        }
      } catch (err) {
        dispatch({
          type: HOME_GET_VERSIONS_FAILURE,
          data: { error: err },
        });
        reject(err);
        console.log('error>>>>>getVersions', err);
      }
    });

    return promise;
  };
}

export function dismissGetVersionsError() {
  return {
    type: HOME_GET_VERSIONS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_VERSIONS_BEGIN:
      return {
        ...state,
        getVersionsPending: true,
        getVersionsError: null,
      };

    case HOME_GET_VERSIONS_SUCCESS:
      return {
        ...state,
        getVersionsPending: false,
        getVersionsError: null,
      };

    case HOME_GET_VERSIONS_FAILURE:
      return {
        ...state,
        getVersionsPending: false,
        getVersionsError: action.data.error,
      };

    case HOME_GET_VERSIONS_DISMISS_ERROR:
      return {
        ...state,
        getVersionsError: null,
      };

    default:
      return state;
  }
}
