export const LOGIN_LOGIN_BEGIN = 'LOGIN_LOGIN_BEGIN';
export const LOGIN_LOGIN_SUCCESS = 'LOGIN_LOGIN_SUCCESS';
export const LOGIN_LOGIN_FAILURE = 'LOGIN_LOGIN_FAILURE';
export const LOGIN_LOGOUT_REQUEST = 'LOGIN_LOGOUT_REQUEST';
export const LOGIN_LOGIN_DISMISS_ERROR = 'LOGIN_LOGIN_DISMISS_ERROR';
export const LOGIN_SIGN_UP_BEGIN = 'LOGIN_SIGN_UP_BEGIN';
export const LOGIN_SIGN_UP_SUCCESS = 'LOGIN_SIGN_UP_SUCCESS';
export const LOGIN_SIGN_UP_FAILURE = 'LOGIN_SIGN_UP_FAILURE';
export const LOGIN_SIGN_UP_DISMISS_ERROR = 'LOGIN_SIGN_UP_DISMISS_ERROR';
export const LOGIN_FORGOT_PASSWORD_BEGIN = 'LOGIN_FORGOT_PASSWORD_BEGIN';
export const LOGIN_FORGOT_PASSWORD_SUCCESS = 'LOGIN_FORGOT_PASSWORD_SUCCESS';
export const LOGIN_FORGOT_PASSWORD_FAILURE = 'LOGIN_FORGOT_PASSWORD_FAILURE';
export const LOGIN_FORGOT_PASSWORD_DISMISS_ERROR = 'LOGIN_FORGOT_PASSWORD_DISMISS_ERROR';
export const LOGIN_RESET_PASSWORD_BEGIN = 'LOGIN_RESET_PASSWORD_BEGIN';
export const LOGIN_RESET_PASSWORD_SUCCESS = 'LOGIN_RESET_PASSWORD_SUCCESS';
export const LOGIN_RESET_PASSWORD_FAILURE = 'LOGIN_RESET_PASSWORD_FAILURE';
export const LOGIN_RESET_PASSWORD_DISMISS_ERROR = 'LOGIN_RESET_PASSWORD_DISMISS_ERROR';
export const LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN = 'LOGIN_CONFIRM_CLIENT_REGISTER_BEGIN';
export const LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS = 'LOGIN_CONFIRM_CLIENT_REGISTER_SUCCESS';
export const LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE = 'LOGIN_CONFIRM_CLIENT_REGISTER_FAILURE';
export const LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR = 'LOGIN_CONFIRM_CLIENT_REGISTER_DISMISS_ERROR';
