const initialState = {
  signUpPending: false,
  signUpError: null,
  loginAuthPending: false,
  loginAuthError: null,
  projectList: {},
  clientDetail: {},
  createProject: {},
  project: {},
  teamMemberList: {},
  orgClient: {},
  clientProjects: {},
  checkClonig: {},
  templatesData: {},
  getAllProjectsPending: false,
  getAllProjectsError: null,
  clientDetailsPending: false,
  clientDetailsError: null,
  createClientProjectPending: false,
  createClientProjectError: null,
  fetchProjectPending: false,
  fetchProjectError: null,
  deleteClientProjectPending: false,
  deleteClientProjectError: null,
  updateClientProjectPending: false,
  updateClientProjectError: null,
  changeProjectPermissionPending: false,
  changeProjectPermissionError: null,
  transferOwnershipPending: false,
  transferOwnershipError: null,
  inviteTeamMemberPending: false,
  inviteTeamMemberError: null,
  teamMemberListPending: false,
  teamMemberListError: null,
  getVersionsPending: false,
  getVersionsError: null,
  teamMemberPendingRequestsPending: false,
  teamMemberPendingRequestsError: null,
  removeTeamMemeberPending: false,
  removeTeamMemeberError: null,
  projectActivitiesPending: false,
  projectActivitiesError: null,
  clientOrganizationPending: false,
  clientOrganizationError: null,
  updateProfilePending: false,
  updateProfileError: null,
  deleteClientProfilePending: false,
  deleteClientProfileError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  clientProjectsPending: false,
  clientProjectsError: null,
  getTemplatesPending: false,
  getTemplatesError: null,
  checkCloningPending: false,
  checkCloningError: null,
  cloneProjectPending: false,
  cloneProjectError: null,
  cloningRequestPending: false,
  cloningRequestError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  clonePendingRequestPending: false,
  clonePendingRequestError: null,
  projectCloneStatusPending: false,
  projectCloneStatusError: null,
  clientPendingRequestPending: false,
  clientPendingRequestError: null,
  stripePaymentPending: false,
  stripePaymentError: null,
};

export default initialState;
