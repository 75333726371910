import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import * as actions from './redux/actions';
import CountryData from '../common/utils/country-data.json';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';
import CustomLoader from '../common/CustomLoader';
import { registerValidation } from '../common/utils/form-validation';
import DashboardFooter from '../home/Footer';

export class Register extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    showForm: true,
    successMessage: 'Congratulations!',
    signupSuccessMessage:
      'Your account has been created. Please check your inbox and click on the link to confirm your email address.',
  };

  componentDidMount() {
    document.title = 'Register | Visually Build Web Applications | No Code ...';
    document.querySelector('body').classList.remove('bg-gray-200');
  }

  handleSubmit = async (values, { resetForm }) => {
    const { actions } = this.props;
    if (values.fullname.trim().split(' ').length < 2) {
      return;
    }
    const fullname = values.fullname;
    const [firstName, lastName] = fullname.split(' ');
    const data = {
      firstName,
      lastName,
      password: values.password,
      username: values.email,
      country: values.country ? values.country.value : null,
    };

    try {
      const response = await actions.signUp({ data });

      if (response && response.status === 201) {
        this.setState({
          successMessage: 'Congratulations!',
          showForm: false,
          signupSuccessMessage:
            'Your account has been created. Please check your inbox and click on the link to confirm your email address.',
        });
        resetForm();
      } else {
        console.error('Error signing up:');
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  render() {
    const { login } = this.props;
    const { signUpPending } = login;
    const { showForm, successMessage, signupSuccessMessage } = this.state;

    return (
      <main className="main-content mt-0">
        {showForm ? (
          <section>
            <div className="page-header min-vh-100">
              <div className="container">
                <div className="row">
                  <a href="https://drapcode.com/" target="_blank">
                    <div className="col-md-12">
                      <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                    </div>
                  </a>
                  <div className="col-12 d-flex justify-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-7">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="font-weight-bolder">Create your Free Account!</h4>
                        </div>
                        <div className="card-body">
                          <Formik
                            initialValues={{
                              fullname: '',
                              email: '',
                              password: '',
                              country: '',
                              agreeToTerms: false,
                            }}
                            validationSchema={registerValidation}
                            onSubmit={this.handleSubmit}
                          >
                            {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                              <Form>
                                <div className="input-group input-group-outline mb-3">
                                  <Field
                                    type="text"
                                    name="fullname"
                                    placeholder="Full Name"
                                    className={`form-control ${
                                      touched.fullname && errors.fullname ? 'is-invalid' : ''
                                    }`}
                                  />
                                  {touched.fullname && errors.fullname && (
                                    <div className="invalid-feedback">{errors.fullname}</div>
                                  )}
                                </div>
                                <div className="input-group input-group-outline mb-3">
                                  <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className={`form-control ${
                                      touched.email && errors.email ? 'is-invalid' : ''
                                    }`}
                                  />
                                  {touched.email && errors.email && (
                                    <div className="invalid-feedback">{errors.email}</div>
                                  )}
                                </div>
                                <div className="input-group input-group-outline mb-3">
                                  <Field
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className={`form-control ${
                                      touched.password && errors.password ? 'is-invalid' : ''
                                    }`}
                                  />
                                  {touched.password && errors.password && (
                                    <div className="invalid-feedback">{errors.password}</div>
                                  )}
                                </div>
                                <div className="input-group-outline border-bottom-end-radius-4 mb-3">
                                  <Field
                                    name="country"
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={CountryData}
                                        onChange={(selectedOption) => {
                                          setFieldValue('country', selectedOption);
                                        }}
                                        value={values.country}
                                        placeholder="Select Country"
                                        className={`custom-select ${
                                          touched.country && errors.country ? 'is-invalid' : ''
                                        }`}
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            border: '1px solid #d2d6da',
                                          }),
                                        }}
                                      />
                                    )}
                                  />
                                  {touched.country && errors.country && (
                                    <div className="invalid-feedback">{errors.country.label}</div>
                                  )}
                                </div>
                                <div className="form-check form-check-info text-start ps-0">
                                  <Field
                                    type="checkbox"
                                    name="agreeToTerms"
                                    className="form-check-input mr-3"
                                  />
                                  <label className="form-check-label mt-1">
                                    I have read & agree to the{' '}
                                    <a
                                      href="https://drapcode.com/legal/terms-of-use"
                                      className="text-dark font-weight-bolder"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </a>
                                  </label>

                                  <ErrorMessage
                                    name="agreeToTerms"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                                    disabled={isSubmitting || signUpPending}
                                  >
                                    Sign Up
                                    <CustomLoader loading={isSubmitting || signUpPending} />
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                        <div className="card-footer text-center pt-0 px-lg-2 px-1">
                          <p className="mb-3 text-sm mx-auto">
                            Already have an account?&nbsp;
                            <Link to={'/'} className="text-primary font-weight-bold">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <main className="main-content mt-0">
            <section>
              <div className="page-header min-vh-100">
                <div className="container">
                  <div className="row">
                    <a href="https://drapcode.com/" target="_blank">
                      <div className="col-md-12">
                        <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                      </div>
                    </a>
                    <div className="col-md-12">
                      <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                        <div className="card">
                          <div className="card-header text-center">
                            <h3 className="font-weight-bolder">{successMessage}</h3>
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="text-center">
                                <h5 className="text-center">{signupSuccessMessage}</h5>
                              </div>
                            </div>
                            <div className="card-footer text-center pt-0 px-lg-2 px-1">
                              <p className="mb-2 text-sm mx-auto">
                                <Link to={'/'} className="text-primary font-weight-bold">
                                  <button className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">
                                    {' '}
                                    Go to login page
                                  </button>
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="fixed-bottom">
              <DashboardFooter />
            </div>
          </main>
        )}
        <div className="fixed-bottom">
          <DashboardFooter />
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
