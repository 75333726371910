import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Select from 'react-select';
import ChangeProjectPermission from './ChangeProjectPermission';
import TransferOwnership from './TransferOwnership';
import Swal from 'sweetalert2';
import timeZoneOptions from '../common/utils/timezone-data.json';
import { bsFrameworkVersionOptions } from '../common/constants';
import CustomLoader from '../common/CustomLoader';
import { projectValidationSchema } from '../common/utils/form-validation';
import Dropzone from 'react-dropzone';
import DeveloperApis from './DeveloperApis';

export class Setting extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    selectedTimezone: '',
    selectedBootstrapVersion: '',
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    apiDomainName: '',
    domainName: '',
    projectName: '',
    projectType: '',
    faviconKeyName: '',
    projectLogoKeyName: '',
    projectDescription: '',
  };

  async componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions, props } = this.props;
    const { params } = props.match;
    const { projectUuid } = params;
    await actions.fetchProject({ projectId: projectUuid });
    const { home } = this.props;
    const { project } = home;
    const selectedBootstrapOption = bsFrameworkVersionOptions.find(
      (option) => option.version === project.framework.version,
    );

    this.setState({
      selectedBootstrapVersion: selectedBootstrapOption ? selectedBootstrapOption : '',
    });

    this.setState({
      apiDomainName: project.apiDomainName,
      domainName: project.domainName,
      projectName: project.name,
      projectType: project.projectType,
      selectedTimezone: project.timezone,
      faviconKeyName: project.faviconKeyName,
      projectLogoKeyName: project.projectLogoKeyName,
      projectDescription: project.description,
    });
    await actions.getVersions({ projectId: projectUuid });
  }

  handleDrop = (acceptedFiles, fieldName, setFieldValue) => {
    setFieldValue(fieldName, acceptedFiles[0]);
  };

  renderDropzone = (fieldName, label, setFieldValue, previewUrl, touched, errors, defaultValue) => {
    return (
      <div className="form-group position-relative">
        <label className="col-form-label">{label}</label>
        <div className="text-center m-2">
          {' '}
          {defaultValue ? (
            <img src={defaultValue} style={{ width: '50%', maxWidth: '100%', height: 'auto' }} />
          ) : (
            ''
          )}
        </div>
        <div className="custom-file">
          <Dropzone
            onDrop={(acceptedFiles) => this.handleDrop(acceptedFiles, fieldName, setFieldValue)}
            accept=".jpg,.jpeg,.gif,.ico,.png"
            maxSize={5242880}
            className="dropzone"
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`dropzone-wrapper ${previewUrl ? 'with-preview' : ''}`}
              >
                <input {...getInputProps()} />
                <div>
                  {previewUrl ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-link cancel-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(fieldName, null);
                        }}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                      <img src={previewUrl} alt="Preview" className="img-thumbnail" />
                    </>
                  ) : (
                    <>
                      Drag & drop or click to select an image{' '}
                      <i
                        className="fas fa-cloud-upload-alt upload-icon"
                        style={{ fontSize: '30px' }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
          <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />
        </div>
      </div>
    );
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { actions, props } = this.props;
    const { params } = props.match;
    const { projectUuid } = params;
    const { selectedTimezone, selectedBootstrapVersion, apiDomainName, domainName, projectType } =
      this.state;
    setSubmitting(true);

    try {
      const cleanedProjectName = values.projectName.replace(/\s+/g, ' ').trim();
      const formData = new FormData();
      formData.append('name', cleanedProjectName);
      formData.append('description', values.projectDescription);
      formData.append('framework', selectedBootstrapVersion.value);
      if (values.favicon) {
        formData.append('favicon', values.favicon);
      }
      if (values.logo) {
        formData.append('projectLogo', values.logo);
      }

      formData.append('timezone', selectedTimezone);
      formData.append('projectType', projectType);
      formData.append('domainName', domainName);
      formData.append('apiDomainName', apiDomainName);
      await actions.updateClientProject({ projectId: projectUuid, formData });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };
  logSelectedValue = (fieldName, selectedOption, form) => {
    this.setState({ [`selected${fieldName}`]: selectedOption ? selectedOption.value : '' });
    form.setFieldValue(fieldName, selectedOption ? selectedOption.value : '');
  };
  deleteProject = async (projectName) => {
    const { props, actions } = this.props;
    const { match } = props;
    const { params } = match;
    const { projectUuid } = params;

    Swal.fire({
      icon: 'warning',
      title: `This will delete the project and all settings associated with it.`,
      html: `Please type <b>${projectName}</b> to confirm.`,
      input: 'text',
      showCancelButton: true,
      inputAutoTrim: true,
      inputPlaceholder: 'Project Name',
      confirmButtonText: 'Confirm',
      inputValidator: (input) => {
        return new Promise((resolve) => {
          if (!input) {
            resolve('You need to write the Project Name!');
          } else if (input === projectName) {
            resolve();
          } else {
            resolve('Incorrect Project Name.');
          }
        });
      },
    }).then((result) => {
      if (result.value) {
        actions.deleteClientProject({ projectId: projectUuid });
      }
    });
  };
  render() {
    const { home, REACT_APP_S3_URL } = this.props;
    const { project, updateClientProjectPending, deleteClientProjectPending } = home;
    const { protocol, selectedBootstrapVersion, faviconKeyName, projectLogoKeyName } = this.state;
    const defaultFavicon = faviconKeyName ? `${REACT_APP_S3_URL}${faviconKeyName}` : null;
    const defaultLogo = projectLogoKeyName ? `${REACT_APP_S3_URL}${projectLogoKeyName}` : null;

    return (
      <div className="container-fluid">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-xl-6">
              <div className="card bg-white h-100">
                <div className="card-body">
                  <div className="mt-1">
                    <h4 className="font-weight-bolder">Settings</h4>
                  </div>
                  <hr className="dark horizontal my-3" />
                  <div className="row">
                    <div className="card-body col-md-6">
                      <Formik
                        enableReinitialize
                        initialValues={{
                          projectName: project.name || this.state.projectName,
                          favicon: null,
                          logo: null,
                          apiDomainName: this.state.apiDomainName,
                          domainName: this.state.domainName,
                          projectDescription: project.description || this.state.projectDescription,
                        }}
                        validationSchema={projectValidationSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                          <Form encType="multipart/form-data">
                            <div className="form-group">
                              <label className="col-form-label">Name</label>
                              <Field
                                type="text"
                                name="projectName"
                                placeholder="Name"
                                value={values.projectName}
                                onChange={(e) => {
                                  this.setState({ projectName: e.target.value }, () => {
                                    setFieldValue('projectName', this.state.projectName);
                                  });
                                }}
                                className={`form-control ${
                                  touched.projectName && errors.projectName ? 'is-invalid' : ''
                                }`}
                                style={{
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  padding: '0.375rem 0.75rem',
                                }}
                              />
                              {touched.projectName && errors.projectName && (
                                <div className="invalid-feedback">{errors.projectName}</div>
                              )}
                            </div>

                            <div className="form-group mt-3">
                              <label className="col-form-label">Description</label>
                              <Field
                                as="textarea"
                                name="projectDescription"
                                placeholder="Description"
                                value={values.projectDescription}
                                onChange={(e) => {
                                  this.setState({ projectDescription: e.target.value }, () => {
                                    setFieldValue(
                                      'projectDescription',
                                      this.state.projectDescription,
                                    );
                                  });
                                }}
                                className={`form-control ${
                                  touched.projectDescription && errors.projectDescription
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                style={{
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  padding: '0.375rem 0.75rem',
                                }}
                              />
                              {touched.projectDescription && errors.projectDescription && (
                                <div className="invalid-feedback">{errors.projectDescription}</div>
                              )}
                            </div>
                            <div className="form-group mt-3">
                              <label className="col-form-label">
                                Internal Domain{' '}
                                <small>
                                  (A default domain to test and launch your no code app.)
                                </small>
                              </label>
                              <br />
                              {project.domainName && (
                                <a
                                  style={{ marginLeft: '0.25rem' }}
                                  href={`${protocol}://${project.domainName}`}
                                  target="_blank"
                                >
                                  {`${protocol}://${project.domainName}`}
                                </a>
                              )}
                            </div>
                            <div className="form-group mt-3">
                              <label className="col-form-label">
                                Custom Domain (To enable it please upgrade your account.)
                                <br />
                                {protocol}://
                              </label>
                              <Field
                                name="domainName"
                                value={this.state.domainName}
                                readOnly
                                className={`form-control ${
                                  touched.domainName && errors.domainName ? 'is-invalid' : ''
                                }`}
                                style={{
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  padding: '0.375rem 0.75rem',
                                }}
                              />
                              {touched.domainName && errors.domainName && (
                                <div className="invalid-feedback">{errors.domainName}</div>
                              )}
                            </div>
                            <div className="form-group mt-3">
                              <label className="col-form-label">
                                Custom API Domain (To enable it please upgrade your account.)
                                <br />
                                {protocol}://
                              </label>
                              <Field
                                name="apiDomainName"
                                value={this.state.apiDomainName}
                                readOnly
                                className={`form-control ${
                                  touched.apiDomainName && errors.apiDomainName ? 'is-invalid' : ''
                                }`}
                                style={{
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  padding: '0.375rem 0.75rem',
                                }}
                              />
                              {touched.apiDomainName && errors.apiDomainName && (
                                <div className="invalid-feedback">{errors.apiDomainName}</div>
                              )}
                            </div>

                            <div className="form-group mt-3">
                              <label className="col-form-label">Timezone</label>
                              <Field
                                name="timezone"
                                render={({ field, form }) => (
                                  <Select
                                    {...field}
                                    options={timeZoneOptions}
                                    placeholder="Select Timezone"
                                    value={timeZoneOptions.find(
                                      (option) => option.value === this.state.selectedTimezone,
                                    )} // Set the pre-existing value
                                    onChange={(option) =>
                                      this.logSelectedValue('Timezone', option, form)
                                    }
                                    onBlur={() => form.setFieldTouched('timezone', true)}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="timezone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label className="col-form-label">Bootstrap Version</label>
                              <Field
                                name="bootstrapVersion"
                                value={selectedBootstrapVersion.label}
                                readOnly
                                className={`form-control ${
                                  touched.selectedBootstrapVersion &&
                                  errors.selectedBootstrapVersion
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                style={{
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.25rem',
                                  padding: '0.375rem 0.75rem',
                                }}
                              />
                              {touched.selectedBootstrapVersion &&
                                errors.selectedBootstrapVersion && (
                                  <div className="invalid-feedback">
                                    {errors.selectedBootstrapVersion}
                                  </div>
                                )}
                            </div>
                            {this.renderDropzone(
                              'favicon',
                              'Favicon',
                              setFieldValue,
                              values.favicon && URL.createObjectURL(values.favicon),
                              touched,
                              errors,
                              defaultFavicon,
                            )}
                            {this.renderDropzone(
                              'logo',
                              'Icon/Logo Image',
                              setFieldValue,

                              values.logo && URL.createObjectURL(values.logo),
                              touched,
                              errors,
                              defaultLogo,
                            )}
                            <hr className="form-divider" />
                            <div className="text-center">
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger mt-4 mb-2 ml-5"
                                  disabled={deleteClientProjectPending}
                                  onClick={() => this.deleteProject(project.name)}
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  <i className="material-icons text-lg position-relative">delete</i>
                                  Delete
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success btn-md text-white mt-4 mb-2 "
                                  disabled={isSubmitting || updateClientProjectPending}
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  <i className="material-icons text-lg position-relative">save</i>
                                  Update
                                  <CustomLoader
                                    loading={isSubmitting || updateClientProjectPending}
                                  />
                                </button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 d-flex flex-column gap-3">
              <div className="card">
                <ChangeProjectPermission />
              </div>
              <div className="card">
                <TransferOwnership />
              </div>
              <div className="card">
                <DeveloperApis project={project} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    REACT_APP_S3_URL: process.env.REACT_APP_S3_URL,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
