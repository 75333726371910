import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { Link, NavLink } from 'react-router-dom';
import SocialMediaLinks from './SocialMediaLinks';
import ResourceLinks from './ResourceLinks';
import CustomPagination from '../../common/CustomPagination';
import VideoModal from './VideoModal';
import voca from 'voca';
import Layout from './Layout';
import { planOptions } from '../common/constants';
export class Dashboard extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentPage: 1,
    perPage: 12,
    showVideoModal: false,
  };

  componentDidMount() {
    document.title = 'Dashboard-WebConnect';
    const { actions } = this.props;
    const { currentPage, perPage } = this.state;
    actions.getAllProjects({ page: currentPage - 1, size: perPage });
    actions.clientDetails();
  }

  getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const day = date.getDate();
    const ordinalSuffix = this.getOrdinalSuffix(day);
    return formattedDate.replace(/\b\d+\b/, (match) => match + ordinalSuffix);
  };
  handlePageChange = (page) => {
    const { actions } = this.props;
    const { perPage } = this.state;

    const newPage = Math.max(
      1,
      Math.min(page, Math.ceil(this.props.home.projectList.totalProjects / perPage)),
    );

    this.setState({ currentPage: newPage }, () => {
      actions.getAllProjects({ page: newPage - 1, size: perPage });
    });
  };

  openVideoModal = () => {
    this.setState({ showVideoModal: true });
  };

  closeVideoModal = () => {
    this.setState({ showVideoModal: false });
  };

  getBadgeClassName(projectType) {
    switch (projectType) {
      case 'FREE':
        return 'badge-free-plan';
      case 'PAID_1':
        return 'badge-starter-plan';
      case 'PAID_2':
        return 'badge-professional-plan';
      case 'PAID_3':
        return 'badge-growth-plan';
      case 'PAID_4':
        return 'badge-enterprise1-plan';
      case 'PAID_5':
        return 'badge-enterprise2-plan';
      case 'PAID_6':
        return 'badge-enterprise3-plan';
      case 'PAID_7':
        return 'badge-enterprise4-plan';
      default:
        return '';
    }
  }

  getProjectTypeLabel = (value) => {
    const planOption = planOptions.find((option) => option.value === value);
    return planOption ? planOption.label : 'N/A';
  };

  renderProjects() {
    const { login, home, protocol, builderSurface, exchange_surface } = this.props;
    const token = login.token;
    const { content } = home.projectList.filteredProjects || {};
    if (!content) {
      return null;
    }
    return content.map((project, index) => (
      <div className="col-lg-4 col-md-6 mt-4 mb-4" key={index}>
        <div className="card">
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-lg-10 col-10">
                <h6
                  className="text-dark text-lg font-weight-bold  mb-0"
                  title={`${voca.titleCase(project.name)}`}
                >
                  {voca.truncate(voca.titleCase(project.name), 25)}
                </h6>
              </div>
              <div className="col-lg-2 col-2 my-auto text-end">
                <Link
                  to={`/client/${project.uuid}/project-detail`}
                  className="setting-icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Project Details"
                  style={{ textDecoration: 'none', color: '#344767' }}
                >
                  <i className="fas fa-cog " style={{ cursor: 'pointer', fontSize: '21px' }}></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="timeline-one-side">
              {/* dont remove this cmt */}
              {/* <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="material-icons text-primary text-gradient">attach_money</i>
                </span>
                <div className="timeline-content">
                  <h6
                    className={`text-sm font-weight-bold mb-0 text-center ${this.getBadgeClassName(
                      project.projectType,
                    )}`}
                    style={{ borderRadius: '4px' }}
                  >
                    {this.getProjectTypeLabel(project.projectType)}
                  </h6>
                </div>
              </div> */}
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="material-icons text-primary text-gradient">watch_later</i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    {this.formatDate(new Date(project.dateCreated))}
                  </h6>
                </div>
              </div>
              <div className="timeline-block mb-3">
                <span className="timeline-step">
                  <i className="material-icons text-danger text-gradient">code</i>
                </span>
                <div className="timeline-content">
                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                    {project.framework
                      ? project.framework.name + ' ' + project.framework.version
                      : 'N/A'}
                  </h6>
                </div>
              </div>
            </div>
            <hr className="dark horizontal"></hr>
            <div className="d-flex mx-2 justify-content-between">
              <a
                href={`${protocol}://${project.seoName}.${builderSurface}editor/${1}/v/${
                  project.uuid
                }/loader?token=${token}`}
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Configure Project"
                className="btn btn-sm btn-success text-white"
              >
                <i className="fas fa-cog " style={{ fontSize: '12px' }}></i> Project Builder
              </a>
              <a
                href={`${protocol}://${project.seoName}.${exchange_surface}`}
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Live App"
                className="btn btn-sm  view-button"
              >
                <i className="fas fa-eye" style={{ fontSize: '11px' }}></i> View
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  calculateProjectStepPer = (project) => {
    if (!project) {
      return 0;
    }
    const { steps } = project;
    if (!steps || steps.length === 0) {
      return 0;
    }
    const perComplete = steps.map((step) => step.value).reduce((acc, step) => acc + step);
    return perComplete;
  };

  render() {
    const { home, login, protocol, project_domain } = this.props;
    const { projectList, clientDetail } = home;
    const { name } = login;
    const { maxFreeProjects } = clientDetail ? clientDetail : {};
    const { totalProjects, filteredProjects } = projectList ? projectList : {};
    const { totalPages } = filteredProjects ? filteredProjects : 0;
    const { currentPage, perPage, showVideoModal } = this.state;
    return (
      <Layout
        maxFreeProjects={maxFreeProjects}
        totalProjects={totalProjects}
        currentPage={'Dashboard'}
      >
        <div className="row mt-4">
          {totalProjects === 0 ? (
            <>
              <div className="col-lg-12 d-flex align-items-center justify-content-center">
                <div className="tm-section-title custom-h1-tm-section-title text-center mt-5">
                  <h3>Welcome!</h3>
                  <p className="text-dark">
                    We are excited to commence your DrapCode journey! Get ready
                    <br /> to give your cool ideas a concrete shape by building amazing
                    <br /> web apps.
                  </p>
                  {/* dont remove this cmt */}
                  {/* <button className="btn btn-primary p-2" onClick={this.openVideoModal}>
                    <img
                      src="https://app.drapcode.com/public/maxresdefault%20(1).jpg"
                      style={{ width: '480px' }}
                      alt="Short Intro Video"
                    />
                  </button> */}
                  <Link
                    className="btn btn-success text-white btn-md mb-0 me-3 mt-5"
                    to={'/client/create-project-select'}
                  >
                    <i className="fa fa-plus me-sm-1"></i>
                    Create Project
                  </Link>
                </div>
              </div>
              {/* dont remove this cmt */}
              {/* <ResourceLinks protocol={protocol} projectDomain={project_domain} /> */}
              <div>{/* <SocialMediaLinks /> */}</div>
            </>
          ) : (
            <>
              <div className="tm-section-title custom-h1-tm-section-title d-flex justify-content-end mb-3">
                {totalProjects < maxFreeProjects ? (
                  <Link
                    className="btn btn-success text-white btn-md mb-0 me-3"
                    to={'/client/create-project-select'}
                  >
                    {' '}
                    <i className="fa fa-plus me-sm-1"></i>
                    Create Project
                  </Link>
                ) : (
                  <p>Free project limit exceeded.</p>
                )}
              </div>
              {this.renderProjects()}
            </>
          )}
        </div>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={this.handlePageChange}
        />
        <VideoModal isOpen={showVideoModal} toggle={this.closeVideoModal} />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderSurface: process.env.REACT_APP_BUILDER_SURFACE,
    configurationSurface: process.env.REACT_APP_CONFIGURATION_SURFACE,
    exchange_surface: process.env.REACT_APP_EXCHANGE_SURFACE,
    project_domain: process.env.REACT_APP_PROJECT_DOMAIN,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
