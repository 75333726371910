import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Swal from 'sweetalert2';
import CountryOption from '../common/utils/country-data.json';
import ClientProfileForm from './ClientProfileForm';
import Layout from './Layout';
export class ClientProfile extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    selectedCountry: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    country: '',
    registerAddress: '',
    businessName: '',
    contact: '',
    gst: null,
    panNo: null,
    oraganizationName: '',
  };
  async componentDidMount() {
    const { actions } = this.props;
    await actions.clientDetails();
    await actions.clientOrganization();
    const { home } = this.props;
    const { clientDetail, orgClient } = home;
    this.setState({
      firstName: clientDetail.firstName,
      lastName: clientDetail.lastName,
      emailAddress: clientDetail.username,
      registerAddress: clientDetail.registerAddress,
      businessName: clientDetail.businessName,
      gst: clientDetail.gst,
      panNo: clientDetail.pan,
      contact: clientDetail.contact,
      oraganizationName: orgClient.name,
      country: orgClient.country,
    });
  }

  handleCountryChange = (selectedOption) => {
    this.setState({ selectedCountry: selectedOption });
  };
  handleSubmit = async (values, { setSubmitting }) => {
    const { actions } = this.props;
    setSubmitting(true);
    const { contact, oraganizationName, registerAddress, gst, panNo } = this.state;
    try {
      const {
        firstName,
        lastName,
        country,
        // contact,
        // oraganizationName,
        businessName,
        // registerAddress,
        // gst,
        // panNo,
      } = values;
      const data = {
        firstName,
        lastName,
        contact,
        country,
        oraganizationName,
        businessName,
        registerAddress,
        gst,
        pan: panNo,
      };
      await actions.updateProfile({ data });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitting(false);
    }
  };
  onChange = (e, setFieldValue, fieldName) => {
    const { value } = e.target;
    this.setState({ [fieldName]: value }, () => {
      setFieldValue(fieldName, value);
    });
  };

  deleteProfile = async () => {
    const { actions, history } = this.props;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      text: "You're about to permanently delete your account. If you're ready to delete it, click Confirm.",
    }).then((result) => {
      if (result.value) {
        actions.deleteClientProfile().then((res) => {
          history.push('/');
        });
      }
    });
  };

  render() {
    const { home } = this.props;
    const {
      firstName,
      lastName,
      emailAddress,
      oraganizationName,
      country,
      registerAddress,
      businessName,
      contact,
      gst,
      panNo,
    } = this.state;
    const { updateProfilePending } = home;
    const countryWithLabel = CountryOption.find((e) => e.value === country);
    const initialValues = {
      firstName,
      lastName,
      contact,
      emailAddress,
      oraganizationName,
      businessName,
      registerAddress,
      gst,
      panNo,
      country: countryWithLabel ? countryWithLabel.value : '',
    };
    return (
      <Layout currentPage={'Profile'}>
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-6 offset-xl-3">
                <div className="card card-plain h-100 shadow-lg">
                  <div className="card-header pb-0 p-3">
                    <div className="row">
                      <div className="col-md-8 d-flex align-items-center">
                        <h4 className="mb-0">Profile Information</h4>
                      </div>
                    </div>
                  </div>
                  <hr class="dark horizontal my-3" />
                  <div className="card-body">
                    <ClientProfileForm
                      initialValues={initialValues}
                      onSubmit={this.handleSubmit}
                      updateProfilePending={updateProfilePending}
                      handleCountryChange={this.handleCountryChange}
                      onDeleteProfile={this.deleteProfile}
                      selectedCountry={this.state.selectedCountry}
                      onChange={(e, setFieldValue, fieldName) =>
                        this.onChange(e, setFieldValue, fieldName)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
