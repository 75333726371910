import * as Yup from 'yup';

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last Name is required.'),
  contact: Yup.string()
    .nullable()
    .matches(/^[0-9]{10}$/, 'Invalid contact number'),
  emailAddress: Yup.string().email('Invalid email address.'),
  oraganizationName: Yup.string().nullable(),
  businessName: Yup.string().nullable(),
  registerAddress: Yup.string().nullable(),
  gst: Yup.string()
    .nullable()
    .matches(/^[0-9]{15}$/, 'Invalid GST number'),
  panNo: Yup.string()
    .nullable()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN number'),
});

export const cloneValidationSchema = Yup.object().shape({
  projectName: Yup.string()
    .required("Can't be blank.")
    .matches(/^[a-zA-Z0-9]+$/, 'Name must contain only alphabets and numbers'),
  projectDescription: Yup.string(),
});

export const projectValidationSchema = Yup.object().shape({
  projectName: Yup.string()
    .required("Can't be blank.")
    .max(50, 'Project name must be at most 50 characters long.')
    .matches(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s]+$/, 'Name must contain only alphabets and numbers'),
  projectDescription: Yup.string(),
});

export const inviteTeamValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

export const settingValidationSchema = Yup.object().shape({
  projectName: Yup.string()
    .required("Can't be blank.")
    .matches(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s]+$/, 'Name must contain only alphabets and numbers'),
  projectDescription: Yup.string(),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .min(5, 'Password must be at least 5 characters'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm New Password is required'),
});

export const registerValidation = (values) => {
  return Yup.object().shape({
    fullname: Yup.string()
      .required('Name is required')
      .test(
        'is-fullname',
        'Please enter a valid Full Name',
        (value) => value && value.trim().split(' ').length >= 2,
      ),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    country: Yup.object().shape({
      value: Yup.string().required('Country is required'),
      label: Yup.string().required('Country is required'),
    }),
    agreeToTerms: Yup.boolean()
      .oneOf([true], 'You must accept the Terms and Conditions')
      .required('You must accept the Terms and Conditions'),
  });
};
