import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import img1 from '../../assets/img/open-builder-1.png';
import img2 from '../../assets/img/open-builder-2.png';
import img3 from '../../assets/img/open-builder-3.png';
import Layout from './Layout';
export class OpenBuilder extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  async componentDidMount() {
    document.title = 'Visually Build Web Applications | No Code Development - ...';
    const { actions, match } = this.props;
    const { params } = match;
    const { projectUuid } = params;
    await actions.fetchProject({ projectId: projectUuid });
  }

  render() {
    const { home, login } = this.props;
    const { project } = home ? home : {};
    const { protocol, builderSurface, configurationSurface } = this.props;
    const token = login.token;
    return (
      <Layout currentPage={'OpenBuilder'}>
        <div className="row">
          <div className="col-lg-12">
            <main className="text-center p-4">
              <h2 className="mb-3">What to do next?</h2>
              <p>Now follow these steps one by one and enjoy making your first no-code web app.</p>
              <p>
                Use drag-and-drop components and readymade templates to build your app. Create a
                dynamic database using collections and automated workflow events. Publish your web
                app with a click of a button & go live.
              </p>
              <div className="row">
                <div className="col-12 col-md-4 mb-4">
                  <img src={img1} alt="Build your Web App" className="img-fluid" />
                  <h4>Build your Web App</h4>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <img src={img2} alt="Add Collections & Workflows" className="img-fluid" />
                  <h4>Add Collections & Workflows</h4>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <img src={img3} alt="Publish & Go Live" className="img-fluid" />
                  <h4>Publish & Go Live</h4>
                </div>
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <div className="col-6">
                  <a
                    href={`${protocol}://${project.seoName}.${configurationSurface}project-on-board/1/v/${project.uuid}/loader?token=${token}`}
                    target="_blank"
                    className="d-lg-inline-block m-btn m-btn-lg project-table-client-color"
                  >
                    Next: Add Authentication to your application
                  </a>
                </div>
                &nbsp;&nbsp;
                <div className="col-6">
                  <a
                    href={`${protocol}://${project.seoName}.${builderSurface}editor/1/v/${project.uuid}/loader?token=${token}`}
                    target="_blank"
                    className="d-lg-inline-block m-btn m-btn-lg m-btn-theme"
                  >
                    Skip: Go to the builder to start designing
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
    login: state.login,
    protocol: process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
    builderSurface: process.env.REACT_APP_BUILDER_SURFACE,
    configurationSurface: process.env.REACT_APP_CONFIGURATION_SURFACE,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenBuilder);
