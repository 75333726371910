import {
  HOME_CREATE_CLIENT_PROJECT_BEGIN,
  HOME_CREATE_CLIENT_PROJECT_SUCCESS,
  HOME_CREATE_CLIENT_PROJECT_FAILURE,
  HOME_CREATE_CLIENT_PROJECT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { builderAuthHeaderWithNoContentType } from '../../common/utils/auth';

export function createClientProject(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_CREATE_CLIENT_PROJECT_BEGIN,
    });
    const { formData } = args;
    const config = builderAuthHeaderWithNoContentType();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}projects/`,
        formData,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_CREATE_CLIENT_PROJECT_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
          if (res.status === 200 && res.data) {
            toast.success('Project created successfully');
          }
        },
        (err) => {
          dispatch({
            type: HOME_CREATE_CLIENT_PROJECT_FAILURE,
            data: { error: err },
          });
          reject(err);
          toast.error('Failed to create blank project');
        },
      );
    });

    return promise;
  };
}

export function dismissCreateClientProjectError() {
  return {
    type: HOME_CREATE_CLIENT_PROJECT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_CREATE_CLIENT_PROJECT_BEGIN:
      return {
        ...state,
        createClientProjectPending: true,
        createClientProjectError: null,
      };

    case HOME_CREATE_CLIENT_PROJECT_SUCCESS:
      return {
        ...state,
        createClientProjectPending: false,
        createClientProjectError: null,
        createProject: action.data,
      };

    case HOME_CREATE_CLIENT_PROJECT_FAILURE:
      return {
        ...state,
        createClientProjectPending: false,
        createClientProjectError: action.data.error,
      };

    case HOME_CREATE_CLIENT_PROJECT_DISMISS_ERROR:
      return {
        ...state,
        createClientProjectError: null,
      };

    default:
      return state;
  }
}
